import { useState, useEffect } from "react";
import "./home.scss";
import Web3 from "web3";
import { Col, Container, Row, Nav, Navbar } from "react-bootstrap";
import AboutImg from "../../assets/img/tim.jpg";
import Footer from "../../components/Footer/Footer";
import abi from "./abi.json";
import { Link } from "react-router-dom";

function Home() {
  const [web3, setWeb3] = useState("");
  const [addr, setAddr] = useState("");
  const [instance, setInstance] = useState(null);
  const [checkNetwork, setCheckNetwork] = useState("");
  const [totalMinted, setTotalMinted] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(5);

  useEffect(() => {
    try {
      let web3 = new Web3(Web3.givenProvider);
      if (web3.currentProvider.networkVersion != "137") {
        setCheckNetwork("Please switch to Polygon Mainnet network");
      } else {
        setCheckNetwork("");
      }
      setWeb3(web3);

      const ins = new web3.eth.Contract(
        abi,
        "0xd74831a965c528283838258a151ecac084478b0e"
      );
      console.log(ins.methods.price().call());

      setInstance(ins);
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.totalSupply().call();
        console.log(tokenCount);
        setTotalMinted(tokenCount);
        var tokenPrice = await ins.methods.price().call();
        setTokenPrice(tokenPrice / 10 ** 18);
      };
      getTotalMinted();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const checkBlockchain = () => {
    if (web3.currentProvider.networkVersion != "137") {
      setCheckNetwork("Please switch to Polygon Mainnet network");
    } else {
      setCheckNetwork("");
    }
  };

  function connect() {
    web3.eth.requestAccounts().then((res) => {
      setAddr(res[0]);
      web3.eth.defaultAccount = res[0];
    });

    checkBlockchain();
  }

  const mint = async (e) => {
    let num = Number(e);
    if (addr) {
      if (instance) {
        console.log(
          await instance.methods.buy(num).send({
            from: addr,
            value: (
              parseInt(await instance.methods.price().call()) * num
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  return (
    <div className="root">
      <div className="">
        <Container>
          <Navbar className="cus-nav" variant="dark" expand="lg">
            <Navbar.Brand
              href="#top"
              className="font-weight-bold text-uppercase"
            >
              <img
                className="img-fluid nav-logo"
                src={require("../../assets/img/tim.jpg").default}
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#top" className="link-style linkh">
                  Home
                </Nav.Link>
                <Nav.Link href="#about" className="link-style linkh">
                  About
                </Nav.Link>
                <Nav.Link href="#story" className="link-style linkh">
                  Story
                </Nav.Link>
                <Nav.Link href="#roadmap" className="link-style linkh">
                  Roadmap
                </Nav.Link>

                <Nav.Link href="#team" className="link-style linkh">
                  THE TEAM
                </Nav.Link>
                <Link
                  to="/connectpolygon"
                  className="link-style d-flex align-items-center linkh"
                  target="_blank"
                >
                  Polygon Mainnet
                </Link>
                <Nav.Link
                  onClick={connect}
                  className="link-style btn btn-outline-warning"
                >
                  <span>{addr ? addr.substring(0, 15) : "Connect Wallet"}</span>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
      <div id="home" className="home ">
        <div className="showcase"></div>

        <div className="buynftdiv">
          <div className="mt-5 text-center">
            On Mobile? Use Metamask Browser and navigate to &nbsp;
            <a href="https://rudecows.co.uk/" target="_blank">
              https://rudecows.co.uk/
            </a>
          </div>
          <div className="mint">
            <div className="mint-content">
              <div className=" w-100">
                <div className="text-center">
                  <h2 className="font-weight-bold mb-3">Buy Rudecows</h2>
                </div>
                <Container>
                  <Row>
                    <Col lg="6">
                      <div className="rm-1">
                        <button
                          onClick={() => mint(1)}
                          className="btn w-100 btn-pri font-weight-bold"
                        >
                          1
                        </button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="rm-1">
                        <button
                          onClick={() => mint(5)}
                          className="btn w-100 btn-pri font-weight-bold"
                        >
                          5
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="6">
                      <div className="rm-1">
                        <button
                          onClick={() => mint(10)}
                          className="btn w-100 btn-pri font-weight-bold"
                        >
                          10
                        </button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="rm-1">
                        <button
                          onClick={() => mint(25)}
                          className="btn w-100 btn-pri font-weight-bold"
                        >
                          25
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-center mt-3">
                        <div className="font-weight-bold">
                          {totalMinted} / 10000
                        </div>
                        <div className="">
                          <span>Price Per Rudecows : {tokenPrice} MATIC</span>
                        </div>
                        <div className="">
                          <span className="text-warning">{checkNetwork}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about" className="about">
        <Container className=" h-100">
          <Row className=" h-100">
            <Col
              lg="6"
              className="d-flex align-items-center justify-content-center"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="aboutimg p-5">
                <img className="img-fluid about-img" src={AboutImg} alt="" />
              </div>
            </Col>
            <Col
              lg="6"
              className="d-flex align-items-center"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="about-text">
                <h1 className="title text-uppercase text-pri">
                  What is Rudecows?
                </h1>
                <p className="mt-3 subtitle">
                  Rude Cows is initially a collection of 10,026 unique randomly
                  generated Rude Cow NFTs on the Polygon blockchain. The 10,026
                  Rude Cows create our community known as: The Herd. Our Cows
                  originate from Scotland with their wild hair and irreverent
                  attitude to life, you might have seen Highland Cattle in real
                  life?
                </p>
                <p className="subtitle">
                  Rude Cows are cute, fun, cheeky and love life. Rude Cows are
                  known for constant grazing grass, leaving sh*t behind and
                  friendly banter.
                </p>
                <p className="subtitle">
                  Stay safe and hope to see you in the Discord, thanks T
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="story" className="story">
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className="text-center"
        >
          <div className="">
            <a className="btn btn-pri w-25" href="#top">
              Mint
            </a>
          </div>
        </div>
        <div className="banner">
          <img
            className="img-fluid showcase-img"
            src={require("../../assets/img/banner.png").default}
            alt=""
          />
        </div>
        <Container className=" h-100">
          <Row>
            <Col lg="12">
              <div className="content">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="title text-uppercase text-pri"
                >
                  What's the Story?
                </h1>
                <div className="">
                  <p
                    className="subtitle"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    We will be doing our first release in October 2020, date to
                    be confirmed, please watch out on our discord, twitter and
                    instagram to be in with a chance to mint at never to be
                    repeated low prices
                  </p>
                  <p
                    className="subtitle"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    There also be random giveaways at different times and who
                    knows we might even create some real Rude Cows stash,
                    badges, hats who knows?
                  </p>
                  <p
                    className="subtitle text-pri"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    Who is the original Rude Cow?
                  </p>
                  <p
                    className="subtitle"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    Well it is actually a painting by one of the family Liz.
                    Here is the original Painting, we thought it was really cool
                    and we could create a number of randomised characters from
                    this original cheeky cow (the one in the picture, not Liz!).
                  </p>

                  <div
                    className="text-center mt-5 "
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <img
                      className="img-fluid "
                      src={require("../../assets/img/painting.jpeg").default}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="content">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="title text-uppercase text-pri"
                >
                  Giving Back
                </h1>
                <div className="">
                  <p
                    className="subtitle"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    In this first phase we will be donating a minimum of 10% of
                    our sales value (not the profit, the actual sales) to
                    environmental causes, we’ll be starting close to home with a
                    contribution to rewilding projects in Scotland.
                  </p>
                  <p
                    className="subtitle"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    As soon as we have made the first donation we will be
                    showing proof on this website as well as on social.
                    Aspirationally we will want to increase this percentage and
                    amount and we will demonstrate this through our actions as
                    the project progresses, if you have any favourite
                    environmental projects wherever they are, let us know in the
                    discord group.
                  </p>
                  <p
                    className="subtitle"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    There also be random giveaways at different times and who
                    knows we might even create some real Rude Cows stash,
                    badges, hats who knows?
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="content">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="title text-uppercase text-pri"
                >
                  Future Releases
                </h1>
                <div className="">
                  <p
                    className="subtitle"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    Well there will never be another 1st release, but we do have
                    more designs in the pipeline, and they might not be
                    restricted to Highland cows, let us know in the discord what
                    you think we should be doing?
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        data-aos="fade-up"
        data-aos-duration="2000"
        className="mt-5 text-center"
      >
        <div className="">
          <a className="btn btn-pri w-25" href="#top">
            Mint
          </a>
        </div>
      </div>

      <div id="roadmap" className="roadmap">
        <Container className=" h-100">
          <Row className=" h-100">
            <Col lg="12" className="">
              <div className="">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="title text-uppercase text-pri"
                >
                  Roadmap
                </h1>
                <div className=" d-flex justify-content-center text-wrapper">
                  <img
                    className="img-fluid"
                    style={{ borderRadius: "16px" }}
                    src={require("../../assets/img/roadmap.jpg").default}
                    alt=""
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        data-aos="fade-up"
        data-aos-duration="2000"
        className="mt-5 text-center"
      >
        <div className="">
          <a className="btn btn-pri w-25" href="#top">
            Mint
          </a>
        </div>
      </div>
      <div id="team" className="team">
        <Container className=" h-100">
          <div className="" data-aos="fade-up" data-aos-duration="2000">
            <h1 className="title text-pri">THE TEAM</h1>
            <span data-aos="fade-up" data-aos-duration="2000" className="sub">
              The team has worked hard to bring these unique NFTs together, we
              tried to write the following without reference to horns, cow bells
              or anything else udderly cow related.
            </span>
          </div>
          <Row className="h-100 mt-5">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className=""
            >
              <div className="">
                <img
                  className="img-fluid team-img"
                  src={require("../../assets/img/tim.jpg").default}
                  alt=""
                />
                <h3 className="title text-pri mt-2">Tim</h3>
                <p className="subtitle">
                  {" "}
                  Rude Cows Founder , someone has to be the founder, I’m hoping
                  for a stampede, cos it’s not my first rodeo
                </p>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className=""
            >
              <div className="">
                <img
                  className="img-fluid team-img"
                  src={require("../../assets/img/liz.jpg").default}
                  alt=""
                />
                <h3 className="title mt-2 text-pri">Liz</h3>
                <p className="subtitle">
                  Original Artist, the creative one, we’re pleased she painted a
                  cow, this project would have dung tricky without her!
                </p>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className=""
            >
              <div className="">
                <img
                  className="img-fluid team-img"
                  src={require("../../assets/img/paresh.jpg").default}
                  alt=""
                />
                <h3 className="title mt-2 text-pri">Paresh</h3>
                <p className="subtitle">
                  Full-Stack and Blockchain Developer. Paresh does the doing, so
                  everything works just as it should, don’t message him he’s way
                  too busy!
                </p>
              </div>
            </Col>

            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="mt-3"
            >
              <div className="">
                <img
                  className="img-fluid team-img"
                  src={require("../../assets/img/daniel.jpg").default}
                  alt=""
                />
                <h3 className="title mt-2 text-pri">Daniel</h3>
                <p className="subtitle">
                  Product Manager, Daniel tells us what we are doing right and
                  drives the herd.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="" className="story">
        <Container className=" h-100">
          <Row>
            <Col lg="12">
              <div className="content">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="title text-uppercase text-pri"
                >
                  Safety and Scams - Stay Safe
                </h1>
                <div data-aos="fade-up" data-aos-duration="2000" className="">
                  <p className="subtitle">
                    Please know that Rude Cows or anybody on the Rude Cows team
                    has never or will ever reach out to you asking for your
                    wallet seed phrase or private keys, nor will we ask you for
                    money or your social security number. If you receive a DM,
                    email or message of any kind asking for money or sensitive
                    information, it is likely a scam! As always, be very
                    cautious with any personal details you share. There’s a lot
                    of scams out there, please keep your details safe, keep your
                    wits about you especially on social media including in the
                    Discord group.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="content">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="title text-pri text-uppercase"
                >
                  Polygon
                </h1>
                <div data-aos="fade-up" data-aos-duration="2000" className="">
                  <p className="subtitle">
                    We have chosen Polygon for minting our NFTs, the gas prices
                    on Ethereum have become too unpredictable and high, we
                    believe Polygon offers the best value currently.
                  </p>
                  <p className="subtitle">
                    Polygon combines the best of Ethereum and sovereign
                    blockchains into an attractive feature set.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className="mt-5 text-center"
        >
          <div className="">
            <a className="btn btn-pri w-25" href="#top">
              Mint
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
