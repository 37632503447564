import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function PolygonNetwork() {
  return (
    <div className="pt-5">
      <h1 className="text-center">
        <div className="">
          <Link to="/">
            <img
              className="img-fluid nav-logo"
              src={require("../../assets/img/tim.jpg").default}
              alt=""
            />
          </Link>
        </div>
        <br />
        Get Polygon Main Network on Your Wallet
      </h1>
      <Container>
        <Row>
          <Col>
            <div className="pt-5">
              <div className="">
                <p>Setup MetaMask to connect to Matic Network</p>
                <p>
                  Switch the connected blockchain by clicking on the tab saying
                  Main Ethereum Network, we need to add the Matic network.
                </p>
                <img
                  className="img-fluid"
                  src={require("../../assets/img/1.png").default}
                  alt=""
                />
                <p className="mt-3">Scroll down until you find Custom RPC.</p>
                <img
                  className="img-fluid"
                  src={require("../../assets/img/2.png").default}
                  alt=""
                />
                <p className="mt-3">
                  Enter in the Matic Mainnet settings as follows:
                </p>
                <ul>
                  <li>Network Name: Polygon</li>
                  <li>
                    New RPC URL: https://polygon-rpc.com https://polygon-rpc.com
                    https://polygon-rpc.com/ or
                    https://rpc-mainnet.matic.network/ or
                    https://rpc-mainnet.maticvigil.com/
                    https://rpc-mainnet.matic.network or
                    https://rpc-mainnet.matic.quiknode.pro/
                  </li>
                  <li>ChainID: 137</li>
                  <li>Symbol: MATIC</li>
                  <li>Block Explorer URL: https://polygonscan.com/</li>
                  <li>It should look like this -</li>
                </ul>
                <img
                  className="img-fluid"
                  src={require("../../assets/img/3.png").default}
                  alt=""
                />
                <p className="mt-3">Metamask for Polygon</p>
                <p>Click Save.</p>
                <p>
                  You have now connected to the Polygon / Matic mainnet with
                  your Metamask Wallet!
                </p>
                <p>
                  Transactions on the Polygon network requires its native token
                  MATIC which will be used as gas fee.
                </p>
                <p>
                  This will be a tiny fraction of the massive gas fees on the
                  Ethereum network and you are always asked to confirm every
                  transaction, so you know beforehand what it is really costing.
                </p>

                <div className="mt-5 mb-5">
                  <h2>
                    It's Easy to Transfer Your Matic Tokens to Polygon Main
                    Network
                  </h2>
                  <p>
                    Bridging tokens to the Polygon network from Ethereum is
                    going to cost some Ether in gas fees, which we are all
                    trying to avoid. Here's a suggested route around those high
                    Eth gas fees, if you know another way, please let us know or
                    shout it out in the discord.
                  </p>
                  <p>
                    Buy or get your Matic tokens in Crypto.com, setting an
                    account up takes 5 mins you'll need some ID Then have your
                    Polygon Network address handy.
                  </p>
                  <p>
                    Transfer the Matic tokens you need from Crypto.com by
                    withdrawing them, you'll need to paste your address in here.
                    And a few minutes later the Matic will appear in your
                    Metamask wallet
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PolygonNetwork;
