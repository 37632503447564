import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import PolygonNetwork from "./pages/PolygonNetwork/PolygonNetwork";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/connectpolygon">
            <PolygonNetwork />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
