import React from "react";
import "./footer.scss";
import { Col, Container, Row } from "react-bootstrap";

function Footer() {
  return (
    <div className="footer p-3">
      <Container className="">
        <Row>
          <Col lg="4" className="mb-4">
            <div className="mbc text-center footer-img-wrapper ">
              <img
                className="img-fluid footer-logo "
                src={require("../../assets/img/tim.jpg").default}
                alt=""
                rel="noreferrer"
              />
            </div>
          </Col>
          <Col lg="4" className="mb-4">
            <div className="mbc social-logo h-100 d-flex justify-content-around align-items-center text-center">
              <a
                href="https://discord.gg/aPMTbN5w4u"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ height: "25px" }}
                  className="img-fluid  "
                  src={require("../../assets/img/discord.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a>
              <a
                href="https://twitter.com/rudecows"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ height: "25px" }}
                  className="img-fluid  "
                  src={require("../../assets/img/twitter.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a>
              <a
                href="https://www.instagram.com/rudecows/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ height: "25px" }}
                  className="img-fluid"
                  src={require("../../assets/img/instagram.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a>
              <a
                href="https://opensea.io/collection/rudecows-v2"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ height: "35px" }}
                  className="img-fluid  "
                  src={require("../../assets/img/opensea.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a>
            </div>
          </Col>
          <Col lg="4" className="mb-4">
            <div className="mbc h-100   d-flex justify-content-center align-items-center">
              <div className="">
                <span className="font-weight-bold">
                  © {new Date().getFullYear()} Rude Cows. All rights reserved.
                </span>
                <br />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
